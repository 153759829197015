<template>
  <validation-observer ref="refFormObserver">
    <b-modal
      id="modal-modify-documents-VJ"
      size="lg"
      :title="$t('reservation.modifyDocumentsVJ')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      no-close-on-backdrop
      @show="showModal"
    >
      <!-- <b-alert
          v-if="['VJ'].includes(reservationsData?.source)"
          variant="warning"
          show
          class="p-1"
        >
          {{ $t('reservation.addDocuments[\'The current system only supports Passport\']') }}
        </b-alert> -->

      <div
        v-for="(passenger, index) of paxLists"
        :key="passenger.paxId"
        class="border-secondary rounded-lg mb-50 p-50"
      >
        <b-row v-if="documentDataToAdd && documentDataToAdd[index]">
          <!-- ANCHOR - Passenger Name -->
          <b-col
            cols="12"
            class="mb-25 d-flex align-items-center"
          >
            <div class="d-flex-center justify-content-start font-weight-bold">
              <code class="mr-50">{{ $t(`reservation.${passenger.paxType}`) }}</code>
              <span>
                <span v-if="getPassengerTitle(passenger)">({{ getPassengerTitle(passenger) }})</span>
                {{ passenger.lastName }} / {{ passenger.firstName }}
              </span>
            </div>
          </b-col>

          <!-- ANCHOR - Ssr docs show-->
          <b-col
            v-if="!isEmpty(ssrDocs)"
            cols="12"
          >
            <div
              v-for="(ssrDoc, i) of ssrDocs[index]"
              :key="i"
              cols="12"
              class="my-25 mx-1"
            >
              <small class="font-italic">{{ ssrDoc.ssrText }}</small>
            </div>
          </b-col>

          <!-- ANCHOR - Passport show-->
          <b-col
            v-if="!isEmpty(passenger.document)"
            cols="12"
            class="mb-50"
          >
            <div
              v-for="(doc, dIndex) in getPassport(passenger)"
              :key="dIndex"
              class="d-flex d-flex-between"
            >
              <span class="fw-700"> {{ `${getPassport(passenger).length > 1 ? `${$t('reservation.ppOrCccd')} ${dIndex+1}` : `${$t('reservation.ppOrCccd')}` }` }}: {{ doc.number }} {{ doc.nationality }} {{ convertISODateTime(doc.endDate, 'UTC').date || doc.endDate }} {{ doc.isCountry }}</span>
              <b-button
                v-if="dIndex === 0"
                :variant="documentDataToAdd[index].isEdit ? 'outline-danger' : 'primary'"
                size="sm"
                class="rounded-lg mx-25"
                @click="documentDataToAdd[index].isEdit = !documentDataToAdd[index].isEdit"
              >
                {{ documentDataToAdd[index].isEdit ? 'Huỷ sửa' : 'Sửa' }}
              </b-button>
            </div>
          </b-col>

          <b-col
            v-else
            cols="12"
            class="mb-50 d-flex-between"
          >
            <span class="fw-700"> {{ $t('reservation.ppOrCccd') }}: <span class="text-danger">Chưa có</span></span>
            <b-button
              :variant="documentDataToAdd[index].isEdit ? 'outline-danger' : 'success'"
              size="sm"
              class="rounded-lg mx-25"
              @click="documentDataToAdd[index].isEdit = !documentDataToAdd[index].isEdit"
            >
              {{ documentDataToAdd[index].isEdit ? 'Huỷ thêm' : 'Thêm mới' }}
            </b-button>
          </b-col>

          <template v-if="documentDataToAdd[index].isEdit">
            <b-col
              cols="12"
              md="4"
              class="my-25"
            >
              <b-form-select v-model="documentDataToAdd[index].document.docType">
                <b-form-select-option value="C">
                  Căn cước công dân
                </b-form-select-option>
                <b-form-select-option value="P">
                  Hộ chiếu
                </b-form-select-option>
              </b-form-select>
            </b-col>

            <!-- ANCHOR Passport number -->
            <b-col
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index], 'number')"
                :name="$t('reservation.ppOrCccdNum')"
                :vid="`passport-number-${passenger.paxType}-${index}`"
              >
                <b-form-input
                  :id="`passport-number-${passenger.paxType}-${index}`"
                  v-model="documentDataToAdd[index].document.number"
                  :state="getValidationState(validationContext) === false ? false : null"
                  lazy-formatter
                  :formatter="trimAllInputAndUpper"
                  :placeholder="`${$t('reservation.ppOrCccdNum')}`"
                />
                <b-tooltip
                  v-if="validationContext.errors[0]"
                  :target="`passport-number-${passenger.paxType}-${index}`"
                  triggers="hover focus"
                  placement="top"
                  boundary="window"
                  variant="danger"
                  :title="validationContext.errors[0]"
                >
                  <span class="text-white py-25 mb-0">
                    {{ validationContext.errors[0] }}
                  </span>
                </b-tooltip>
              </validation-provider>
            </b-col>

            <!-- ANCHOR Nationality -->
            <b-col
              v-if="['P'].includes(documentDataToAdd[index].document.docType)"
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index])"
                :name="$t('flight.Nationality')"
                :vid="`passport-nationality-${passenger.paxType}-${index}`"
              >
                <IAmCountrySelect
                  :id="`passport-nationality-${passenger.paxType}-${index}`"
                  v-model="documentDataToAdd[index].document.nationality"
                  :placeholder="$t('flight.Nationality')"
                  :contextErrors="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>

            <!-- ANCHOR passport birthday -->
            <b-col
              v-if="['P'].includes(documentDataToAdd[index].document.docType)"
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index])"
                :name="$t('flight.dob')"
                :vid="`passport-birthday-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  :id="`passport-birthday-${passenger.paxType}-${index}`"
                  class="mb-0"
                >
                  <flat-pickr
                    v-model="documentDataToAdd[index].document.birthday"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      :id="`passport-birthday-${passenger.paxType}-${index}`"
                      v-model="documentDataToAdd[index].document.birthday"
                      :name="$t('flight.dob')"
                      class="form-control px-50 py-0"
                      :config="{
                          ...configFlatPickr,
                          altInputClass: 'form-control input px-50 py-0 border-transparent',
                      }"
                      :style="{height: '30px'}"
                      :placeholder="$t('flight.dob')"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`passport-birthday-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="window"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR passport Place Issued -->
            <b-col
              v-if="['P'].includes(documentDataToAdd[index].document.docType)"
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index])"
                :name="$t('flight.placeIssue')"
                :vid="`passport-placeIssue-${passenger.paxType}-${index}`"
              >
                <IAmCountrySelect
                  :id="`passport-placeIssue-${passenger.paxType}-${index}`"
                  v-model="documentDataToAdd[index].document.isCountry"
                  :placeholder="$t('flight.placeIssue')"
                  :contextErrors="validationContext.errors[0]"
                />
              </validation-provider>
            </b-col>

            <!-- ANCHOR passport expirationDate -->
            <b-col
              v-if="['P'].includes(documentDataToAdd[index].document.docType)"
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index])"
                :name="$t('flight.expirationDate')"
                :vid="`passport-expirationDate-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  :id="`passport-expirationDate-${passenger.paxType}-${index}`"
                  class="mb-0"
                >
                  <flat-pickr
                    v-model="documentDataToAdd[index].document.endDate"
                    class="d-none"
                  />
                  <div
                    class="form-control p-0"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      :id="`passport-expirationDate-${passenger.paxType}-${index}`"
                      v-model="documentDataToAdd[index].document.endDate"
                      :name="$t('flight.expirationDate')"
                      class="form-control px-50 py-0"
                      :config="{
                          ...configFlatPickrPassport,
                          altInputClass: 'form-control input px-50 py-0 border-transparent',
                      }"
                      :style="{height: '30px'}"
                      :placeholder="`${$t('flight.expirationDate')}`"
                    />
                  </div>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`passport-expirationDate-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="window"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- ANCHOR gender -->
            <b-col
              v-if="['P'].includes(documentDataToAdd[index].document.docType)"
              cols="12"
              md="4"
              class="my-25"
            >
              <validation-provider
                #default="validationContext"
                :rules="getValidatePassport(documentDataToAdd[index])"
                :name="$t('flight.gender')"
                :vid="`passport-gender-${passenger.paxType}-${index}`"
              >
                <b-form-group
                  :id="`passport-gender-${passenger.paxType}-${index}`"
                  class="mb-0"
                >
                  <v-select
                    v-model="documentDataToAdd[index].document.gender"
                    :reduce="val => val.value"
                    label="label"
                    class="w-100 p-0 bg-white"
                    :class="validationContext.errors[0] ? 'border-danger-select' : ''"
                    :options="genderOptions.filter(item => item.value !== 'OTHER')"
                    :placeholder="$t('flight.gender')"
                  >
                    <template #option="data">
                      <span>
                        {{ $t(data.label) }}
                      </span>
                    </template>
                    <template #selected-option="data">
                      <span>
                        {{ $t(data.label) }}
                      </span>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                  <b-tooltip
                    v-if="validationContext.errors[0]"
                    :target="`passport-gender-${passenger.paxType}-${index}`"
                    triggers="hover focus"
                    placement="top"
                    boundary="window"
                    variant="danger"
                    :title="validationContext.errors[0]"
                  >
                    <span class="text-white py-25 mb-0">
                      {{ validationContext.errors[0] }}
                    </span>
                  </b-tooltip>
                </b-form-group>
              </validation-provider>
            </b-col>
          </template>
          <!-- ANCHOR: Thêm cảnh báo "hộ chiếu sắp hết hạn" nếu thời gian hết hạn hộ chiếu < thời gian bay 6 tháng (https://discord.com/channels/1054696448110903327/1300290501815308328/1300290507137875971) -->
          <small
            v-if="checkPassportLessThan6MonthsBeforeFlightDate(documentDataToAdd[index].document.endDate, flightDate)"
            class="text-danger mx-1 mt-25"
          >
            {{ $t('reservation.passportLessThan6MonthsBeforeFlight') }}
          </small>
        </b-row>
      </div>

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <!-- :disabled="invalid" -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0"
          pill
          @click="submitModifyDocuments"
        >
          <span class="align-middle">{{ $t('confirmation') }}</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BModal, BButton, BRow, BCol, BFormInput,
  BFormGroup, BTooltip, BFormSelect, BFormSelectOption,
  // BAlert,
} from 'bootstrap-vue'
import { computed, ref, toRefs } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import vSelect from 'vue-select'

import VueI18n from '@/libs/i18n'
import { resolveTitle, genderOptions } from '@/constants/selectOptions'
import YearDropdownPlugin from '@/libs/flatpickr/yearDropdownPlugin'
import { checkPassportLessThan6MonthsBeforeFlightDate, convertISODateTime, getMinTime } from '@/@core/utils/filter'

import formValidation from '@core/comp-functions/forms/form-validation'
import { trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

document.addEventListener('focusin', e => {
  if (e.target.closest('.flatpickr-calendar')) {
    e.stopImmediatePropagation()
  }
})

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BFormInput,
    // BAlert,
    BFormGroup,
    BTooltip,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    IAmCountrySelect: () => import('@/components/IAmCountrySelect.vue'),
  },
  props: {
    paxLists: {
      type: Array,
      required: true,
    },
    reservationsData: {
      type: Object,
      default: () => {},
    },
  },
  /*
  - Check là passport VJ thì có field isCountry, nationality
  */
  setup(props) {
    const { modifyDocuments } = useReservationHandle()
    const { toastError } = useToast()

    const blankDocument = {
      document: {
        paxId: '',
        docType: '',
        birthday: '',
        number: '',
        nationality: null,
        residence: '',
        endDate: '',
        isCountry: null,
        issCountry: '',
      },
      id: '',
    }

    const { paxLists, reservationsData } = toRefs(props)

    const ssrDocs = ref(null)
    const documentDataToAdd = ref(null)

    const resetSsrDocs = () => {
      ssrDocs.value = paxLists.value.map(item => {
        if (reservationsData.value.ssr) {
          return reservationsData.value.ssr.filter(i => i.paxId === item.paxId && i.ssrType === 'DOCS')
        }
        return false
      })
    }

    const resetDocumentData = () => {
      documentDataToAdd.value = cloneDeep(paxLists.value.map(item => {
        const paxDoc = item?.document?.find(doc => !!doc.docType && ['P', 'C'].includes(doc.docType) && !!doc.number)
        const isPassport = paxDoc && paxDoc?.nationality && paxDoc?.isCountry
        return {
          isEdit: false,
          ...blankDocument,
          document: {
            ...blankDocument.document,
            docType: isPassport ? 'P' : 'C',
            paxId: item.paxId,
            number: paxDoc?.number || '',
            nationality: isPassport ? paxDoc?.nationality : null,
            isCountry: isPassport ? paxDoc?.isCountry : null,
            endDate: isPassport ? paxDoc?.endDate : '',
            birthday: isPassport ? item?.birthday : '',
            ...(isPassport && paxDoc?.gender && {
              gender: item.gender,
            }),
          },
          operation: paxDoc ? 'UPDATE' : 'ADD',
        }
      }))
    }

    const { refFormObserver, getValidationState } = formValidation(resetDocumentData)

    // ANCHOR flatpickr config
    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref(
      {
        plugins: [
          new YearDropdownPlugin({
            date: new Date(),
            yearStart: 100,
            yearEnd: 0,
          }),
        ],
        dateFormat: 'Y-m-d',
        minDate: '1.1.1920',
        maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )
    const configFlatPickrPassport = ref(
      {
        plugins: [
          new YearDropdownPlugin({
            date: new Date(),
            yearStart: 0,
            yearEnd: 50,
          }),
        ],
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    // ANCHOR show modal
    function showModal() {
      resetDocumentData()
      resetSsrDocs()
    }

    async function submitModifyDocuments() {
      await refFormObserver.value.validate()
      const getDataUpdate = documentDataToAdd.value.filter(item => item.isEdit && !!item.document.number)
      if (isEmpty(getDataUpdate)) {
        toastError({
          title: 'messagesList.error',
          content: 'reservation.addDocuments[\'Please enter passenger passport information\']',
        })
        return
      }

      const modifyPayload = {
        documentUpdates: getDataUpdate.map(it => ({
          ...it,
          document: {
            ...it.document,
            ...(['P'].includes(it.document.docType) && {
              nationality: it.document?.nationality?.longCode || it.document?.nationality,
              isCountry: it.document?.isCountry?.longCode || it.document?.isCountry,
            }),
            ...(['C'].includes(it.document.docType) && {
              endDate: undefined,
              birthday: undefined,
              nationality: undefined,
              isCountry: undefined,
            }),
          },
        })),
        source: reservationsData.value.source,
        agencyCode: reservationsData.value.agency,
        paxCode: reservationsData.value?.paxContact?.code || '',
        pnrNumber: reservationsData.value.bookingCode,
        contact: reservationsData.value.paxContact.id,
      }

      this.$bvModal.show('modal-api-loading')
      modifyDocuments(modifyPayload)
        .then(() => {
          this.$bvModal.hide('modal-modify-documents-VJ')
        })
        .catch(error => {
          console.error({ error })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    function getPassengerTitle(pax) {
      if (pax && !pax.title) {
        const splitName = pax.firstName.split(' ')
        return resolveTitle(splitName[splitName.length - 1])
      }
      return pax.title
    }

    function getValidatePassport(data, field) {
      if (!data.isEdit) return ''
      if (['C'].includes(data.document.docType)) {
        return ['number'].includes(field) ? 'required' : ''
      }
      return 'required'
    }

    function getPassport(pax) {
      if (pax.document && !isEmpty(pax.document)) {
        return pax.document.filter(doc => doc.docType && ['P', 'C'].includes(doc.docType))
      }
      return []
    }
    const flightDate = computed(() => {
      const allDepartureDate = reservationsData.value?.itineraries?.flat().map(item => item.departure.at)
      return getMinTime(allDepartureDate)
    })

    return {
      isEmpty,
      refFormObserver,
      getValidationState,
      documentDataToAdd,
      submitModifyDocuments,
      getPassengerTitle,
      ssrDocs,
      showModal,
      configFlatPickr,
      configFlatPickrPassport,
      genderOptions,
      trimAllInputAndUpper,
      getValidatePassport,
      getPassport,
      convertISODateTime,
      flightDate,
      checkPassportLessThan6MonthsBeforeFlightDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.border-transparent {
  border-color: transparent !important;
  background-color: transparent !important;
}

.border-danger-select {
  ::v-deep .vs__dropdown-toggle {
    border-color: red !important;
  }
}
</style>
